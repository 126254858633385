import { useEffect, useState } from 'react';

const useVisualisation = (recorder: MediaRecorder | undefined) => {
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (recorder && recorder.stream) {
      const audioContext = new window.AudioContext();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(recorder.stream);
      microphone.connect(analyser);

      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Function to analyze sound levels
      const analyzeSound = () => {
        analyser.getByteFrequencyData(dataArray);

        // Calculate the average volume from the frequency data
        const average =
          dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;

        setVolume(average);

        // Continue analyzing every 100ms
        requestAnimationFrame(analyzeSound);
      };

      // Start analyzing
      analyzeSound();
    }

    // Cleanup on component unmount or mediaRecorder change
    return () => {
      if (recorder) {
        recorder.stream?.getTracks().forEach((track) => track.stop());
      }
    };
  }, [recorder]);

  return { volume };
};

export default useVisualisation;
