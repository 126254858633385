import React, {useRef } from "react";
import './CollapsibleContent.css';

interface Props {
  isCollapsed: boolean,
  style?:React.CSSProperties,  
  children?: React.ReactNode
}

const CollapsibleContent: React.FC<Props> = ({
  isCollapsed,
  style,
  children
}) => {
    const contentRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={contentRef}
      className="collapsible-content"
      style={{
        maxHeight: isCollapsed ? "0px" : `${contentRef.current?.scrollHeight || 0}px`,
        marginTop: isCollapsed ? "0" : "40px",
        ...style,
      }}
    >
      <div>{children}</div>
    </div>
  );
};

export default CollapsibleContent;
