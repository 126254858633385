import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { genderList } from '../../../libs/helpers/genderHelper';
import { setQuizAccessToken } from '../../../libs/services/quizAccessService';
import IQuizStat from '../../../interfaces/IQuizStat';
import useIndexTracker from '../../../libs/hooks/useIndexTracker';

interface Props {
  quizStat: IQuizStat;
  updateQuizStat: (params: any) => Promise<any>;
}

const useCoupleIviteeForm = ({ quizStat, updateQuizStat }: Props) => {
  const [age, setAge]: any = useState(null);
  const [location, setLocation]: any = useState(null);
  const [gender, setGender]: any = useState(null);
  const [kids, setKids]: any = useState(null);
  const [isSubmitting, setIsSubmiting]: [boolean, any] = useState(false);

  const { slug } = useParams();
  const navigate = useNavigate();

  const { index: questionIndex, setIndex: setQuestionIndex } = useIndexTracker({
    items: [
      { value: age?.value },
      {
        value:
          (location?.country &&
            (!location.isStateAvailable || location.state)) ??
          null,
      },
      { value: gender?.value },
      { value: kids?.value },
    ],
  });

  useEffect(() => {
    if (location?.isGeolocated) {
      setQuestionIndex(-1);
    }
  }, [location]);

  const getFormParams = () => {
    const params: any = {
      age: age?.value,
      gender: gender?.value,
      kids: kids?.value,
      country: location?.country,
      state_of_usa: location?.state,
    };

    Object.entries(params).forEach(
      ([key, value]) => value === undefined && delete params[key],
    );

    return params;
  };

  const submitForm = () => {
    setIsSubmiting(true);

    const params = getFormParams();

    updateQuizStat(params)
      .then(({ token }: any) => {
        setQuizAccessToken(slug, token);
        redirectToNextStep(token);
      })
      .catch(({ message }) => {
        setIsSubmiting(false);
        toast.error(message);
      });
  };

  const redirectToNextStep = (token: string) => {
    const surveySlug = quizStat?.survey_stats[0]?.quiz_survey?.slug;
    const uri = `/${slug}/${surveySlug}?token=${token}`;

    navigate(uri);
  };

  return {
    isSubmitting,
    age,
    setAge,
    gender,
    setGender,
    kids,
    setKids,
    location,
    setLocation,
    genderOptions,
    kidsOptions,
    submitForm,
    questionIndex,
    setQuestionIndex,
  };
};

const genderOptions = Object.entries(genderList).map(([value, label]) => ({
  label,
  value,
}));

const kidsOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

export default useCoupleIviteeForm;
