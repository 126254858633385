import { useCallback, useRef, useState } from 'react';

export const STATUS_IDLE = 'idle';
export const STATUS_ACTIVE = 'active';

type QueueGroup = {
  pipe: Array<QueueTask>;
  running: boolean;
};

type QueueTask = {
  callback: () => Promise<void>;
  running: boolean;
};

const useGroupedQueue = () => {
  const queue = useRef<Record<string, QueueGroup>>({});
  const [status, setStatus] = useState(STATUS_IDLE);

  const updateStatus = useCallback(() => {
    const hasActiveGroups = Object.keys(queue.current).length > 0;
    setStatus(hasActiveGroups ? STATUS_ACTIVE : STATUS_IDLE);
  }, []);

  const runQueueGroup = useCallback(
    async (groupKey: string) => {
      const group = queue.current[groupKey];
      if (!group || group.pipe.length === 0) return;

      group.running = true;

      // Process tasks in the queue
      while (group.pipe.length > 0) {
        const task = group.pipe.shift();
        if (task && !task.running) {
          task.running = true;
          try {
            await task.callback(); // Execute the task
          } finally {
            task.running = false; // Mark task as no longer running
          }
        }
      }

      // Once all tasks are processed, mark the group as no longer running
      group.running = false;

      // If there are no more tasks in the group, clean it up
      if (group.pipe.length === 0) {
        delete queue.current[groupKey];
        updateStatus(); // Update status after cleaning up
      }
    },
    [updateStatus],
  );

  const enqueue = useCallback(
    (groupKey: string, callback: () => Promise<void>) => {
      // Initialize the group if it doesn't exist
      if (!queue.current[groupKey]) {
        queue.current[groupKey] = { pipe: [], running: false };
      }

      const group: QueueGroup = queue.current[groupKey];

      // Remove tasks that are not running
      group.pipe = group.pipe.filter((task) => task.running);

      // Add the new callback to the queue
      group.pipe.push({ callback, running: false });

      // If no task is currently running, start processing the queue
      if (!group.running) {
        runQueueGroup(groupKey);
      }

      // Update the status
      updateStatus();
    },
    [runQueueGroup, updateStatus],
  );

  return { queue, enqueue, status };
};

export default useGroupedQueue;

export type { QueueGroup, QueueTask };
