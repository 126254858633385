import { InputProps } from './types';
import useDebounce from '../../../../libs/hooks/useDebounce';

const TextareaInput: React.FC<InputProps> = ({
  inputId,
  surveyUserAnswer,
  onChange,
  onDelete,
  disabled,
  debounceTime = 500,
}) => {
  const defaultValue = surveyUserAnswer?.text || '';

  const debounce = useDebounce();

  const changeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (disabled) return;

    const text = e.target.value;
    const charCount = text.trim().length;

    const callback = () => {
      charCount > 0 ? onChange({ text }) : onDelete();
    };

    debounce(inputId, callback, debounceTime);
  };

  return (
    <div className={disabled ? 'opacity-50' : 'opacity-100'}>
      <textarea
        rows={12}
        className='form-control'
        placeholder='Type your response here'
        defaultValue={defaultValue}
        onChange={changeHandler}
        disabled={disabled}
      />
    </div>
  );
};

export default TextareaInput;
