import ISurveyStat from '../../interfaces/ISurveyStat';

export const getHideOnSkipSurveyStatIds = (
  id: number,
  surveyStats: ISurveyStat[],
): number[] => {
  const surveyStat = surveyStats.find((x) => x.id === id);

  if (!surveyStat) {
    return [];
  }

  const hideOnSkipQuizSurveyIds =
    surveyStat.quiz_survey.settings['hidden_on_skip_ids'] ?? [];

  let hideOnSkipSurveyStatIds: number[] = [];

  surveyStats.forEach(({ quiz_survey, id }) => {
    if (hideOnSkipQuizSurveyIds.includes(quiz_survey.id)) {
      hideOnSkipSurveyStatIds.push(id);
    }
  });

  return hideOnSkipSurveyStatIds;
};

export const getHiddenSurveyStatIndexes = (
  surveyStats: ISurveyStat[],
): number[] => {
  let hiddenSurveyStatIndexes: number[] = [];
  let hiddenQuizSurveyIds: number[] = [];

  surveyStats.forEach(({ quiz_survey, is_skipped }) => {
    if (is_skipped) {
      hiddenQuizSurveyIds = hiddenQuizSurveyIds.concat(
        quiz_survey.settings['hidden_on_skip_ids'] ?? [],
      );
    }
  });

  surveyStats.forEach(({ quiz_survey }, index) => {
    if (hiddenQuizSurveyIds.includes(quiz_survey.id)) {
      hiddenSurveyStatIndexes.push(index);
    }
  });

  return hiddenSurveyStatIndexes;
};
