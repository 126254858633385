import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { genderList } from '../../../libs/helpers/genderHelper';
import { setQuizAccessToken } from '../../../libs/services/quizAccessService';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import IQuiz from '../../../interfaces/IQuiz';
import useIndexTracker from '../../../libs/hooks/useIndexTracker';
import IQuizSurvey from '../../../interfaces/IQuizSurvey';
import * as coupleMode from '../couple/CoupleModePicker';

interface Props {
  quiz: IQuiz;
  createQuizStat: (params: any) => Promise<any>;
  setProgress: (params: any) => Function;
  initialType?: any;
  enmEnabled?: any;
}

const useCoupleInviterForm = ({
  quiz,
  createQuizStat,
  setProgress,
  initialType = coupleMode.options[0],
  enmEnabled = false,
}: Props) => {
  const isMounted = useRef(false);

  const [type, setType]: any = useState(initialType);
  const [age, setAge]: any = useState(null);
  const [location, setLocation]: any = useState({});
  const [gender, setGender]: any = useState(null);
  const [kids, setKids]: any = useState(null);
  const [shouldFocus, setShouldFocus]: [boolean, any] = useState(false);

  const navigate = useNavigate();

  const uniqueEmailValidator = function (this: any, value: string) {
    const partnerEmails: any =
      this.options.context?.partners?.map((partner: any) => partner.email) ||
      [];
    const inviterEmail: any = this.options.context?.email;
    const allEmails = [...partnerEmails, inviterEmail];

    const isUnique = allEmails.filter((email) => email === value).length === 1;
    return isUnique;
  };

  const validationSchema: any = Yup.object().shape({
    quiz_id: Yup.number().required(),
    type: Yup.string().required(),
    name: Yup.string().required('Name is required.'),
    email: Yup.string()
      .email('Email format is invalid.')
      .required('Email is required.')
      .test('uniqueEmail', 'Email is already taken.', uniqueEmailValidator),
    age: Yup.number(),
    country: Yup.string().nullable(),
    state_of_usa: Yup.string().nullable(),
    gender: Yup.string(),
    kids: Yup.bool(),
    partners: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        email: Yup.string()
          .email('Email format is invalid.')
          .required('Email is required.')
          .test('uniqueEmail', 'Emails must be unique.', uniqueEmailValidator),
      }),
    ),
  });

  const onSubmit = (values: any, { setSubmitting }: any) => {
    createQuizStat(values)
      .then(({ token }: any) => {
        setQuizAccessToken(quiz.slug, token);
        redirectToNextStep(token);
      })
      .catch(({ message, errors }) => {
        setSubmitting(false);
        setShouldFocus(true);
        form.setErrors(errors);
        toast.error(message);
      });
  };

  const form = useFormik({
    initialValues: {
      quiz_id: quiz.id,
      type: '',
      name: '',
      email: '',
      age: '',
      country: '',
      state_of_usa: '',
      gender: '',
      kids: '',
      partners: [],
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  const redirectToNextStep = (token: string) => {
    const quizSlug = quiz.slug;
    const surveySlug = quiz.quiz_surveys[0]?.slug;
    const uri = `/${quizSlug}/${surveySlug}?token=${token}`;

    navigate(uri);
  };

  const trackerItems = [
    {
      value: !form.errors.type ? form.values.type : null,
      required: true,
    },
    {
      value: !form.errors.email ? form.values.email : null,
      required: true,
    },
    {
      value: form.values.age,
    },
    {
      value:
        (location?.country && (!location.isStateAvailable || location.state)) ??
        null,
    },
    {
      value: form.values.gender,
    },
    {
      value: form.values.kids,
    },
    {
      value: !form.errors.partners ? form.values.partners : null,
    },
  ];

  const { index: questionIndex, setIndex: setQuestionIndex } = useIndexTracker({
    items: trackerItems,
  });

  useEffect(() => {
    if (questionIndex === -1) {
      setShouldFocus(false);
    }
  }, [questionIndex]);

  useEffect(() => {
    updateFormField('type', type?.value);
  }, [type]);

  useEffect(() => {
    updateFormField('age', age?.value);
  }, [age]);

  useEffect(() => {
    const { country = null, state = null, isGeolocated = false } = location;
    const autoFocus = isMounted.current && !isGeolocated;

    updateFormField('country', country, autoFocus);
    updateFormField('state_of_usa', state, autoFocus);
  }, [location]);

  useEffect(() => {
    updateFormField('gender', gender?.value);
  }, [gender]);

  useEffect(() => {
    updateFormField('kids', kids?.value);
  }, [kids]);

  useEffect(() => {
    setShouldFocus(false);
  }, [form.values.name, form.values.email]);

  useEffect(() => {
    updateProgress();
  }, [form, quiz]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const updateProgress = () => {
    let totalSteps = 1;
    const isExclusiveMode = isExclusive() ? 1 : 0;

    quiz.quiz_surveys.forEach((quizSurvey: IQuizSurvey) => {
      if (
        quizSurvey.settings['exclusive'] === undefined ||
        quizSurvey.settings['exclusive'] === isExclusiveMode
      ) {
        totalSteps += quizSurvey.settings['repeat_ep']
          ? form.values.partners.length
          : 1;
      }
    });

    setProgress((prev: any) => ({ ...prev, totalSteps }));
  };

  const updateFormField = (
    name: string,
    value: string,
    autoFocus: boolean = isMounted.current,
  ) => {
    form.setFieldValue(name, value);

    if (autoFocus) {
      setShouldFocus(true);
    }
  };

  const getPartnerLimit = () => coupleMode.defaultLimits[type?.value] || 0;

  const isExclusive = () => type?.value === coupleMode.TYPE_ONE_TO_ONE;

  return {
    form,
    type,
    setType,
    age,
    setAge,
    gender,
    setGender,
    kids,
    setKids,
    location,
    setLocation,
    genderOptions,
    kidsOptions,
    getPartnerLimit,
    questionIndex,
    setQuestionIndex,
    shouldFocus,
    setShouldFocus,
    isExclusive,
    enmEnabled,
  };
};

export default useCoupleInviterForm;

const genderOptions = Object.entries(genderList).map(([value, label]) => ({
  label,
  value,
}));

const kidsOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
