import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useGroupedQueue, { STATUS_IDLE } from '../hooks/useGroupedQueue';

const QuizContext = createContext<any>(null);

export const useQuizContext = () => useContext(QuizContext);

const QuizProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const submitRef = useRef<any>(null);

  const { status, enqueue } = useGroupedQueue();

  useEffect(() => {
    // If the queue in status idle, execute the callback from submitRef
    if (status === STATUS_IDLE && submitRef.current) {
      submitRef.current();
      submitRef.current = null;
    }
  }, [status]);

  const submitAndWait = useCallback(
    (callback: () => Promise<void>) => {
      setIsSubmitting(true);
      if (status === STATUS_IDLE) {
        callback();
      } else {
        // Save the callback to be invoked later when the queue is empty
        submitRef.current = callback;
      }
    },
    [status],
  );

  return (
    <QuizContext.Provider
      value={{
        enqueue,
        isSubmitting,
        setIsSubmitting,
        submitAndWait,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};

export default QuizProvider;
