import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  optimisticAnswerBegin,
  optimisticAnswerSuccess,
  removeUserAnswer,
  setUserAnswer,
} from '../../../../libs/redux/actions/userAnswerActions';
import { AxiosRequestConfig } from 'axios';
import { IOptions } from './types';
import ISurveyStat from '../../../../interfaces/ISurveyStat';
import IQuestion from '../../../../interfaces/IQuestion';
import ISurveyUserAnswer from '../../../../interfaces/ISurveyUserAnswer';
import useDebounce from '../../../../libs/hooks/useDebounce';

interface Props {
  surveyStat: ISurveyStat;
  question: IQuestion;
  surveyUserAnswer: ISurveyUserAnswer | undefined;
  enqueue: (key: string, callback: () => Promise<void>) => void;
  createOrUpdateAnswer: (
    params: Partial<ISurveyUserAnswer> | FormData,
    config?: AxiosRequestConfig,
  ) => Promise<void>;
}

const useQuestionForm = ({
  surveyStat,
  question,
  surveyUserAnswer,
  enqueue,
  createOrUpdateAnswer,
}: Props) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const dispatch = useDispatch();
  const debounce = useDebounce();

  const questionKey: string = `ss-${surveyStat.id}-q-${question.id}`;
  const prevAnswerRef = useRef<ISurveyUserAnswer | undefined | null>(null);
  const questionParams: Partial<ISurveyUserAnswer> = {
    survey_stat_id: surveyStat.id,
    question_id: question.id,
  };

  const submitAnswer = (
    values: Partial<ISurveyUserAnswer>,
    { debounceTime = 200, ...options }: IOptions = {},
  ) => {
    const answerParams: Partial<ISurveyUserAnswer> = {
      ...questionParams,
      ...values,
    };

    const { params, config } = formatRequestProps(answerParams, options);

    // Save previous answer in case optimistic request fails
    if (!prevAnswerRef.current) {
      prevAnswerRef.current = surveyUserAnswer;
    }
    // Dispatch success action immediately for optimistic UI updates
    dispatch(optimisticAnswerBegin(answerParams));

    // Function to enqueue the request callback
    const enqueueRequest = () => {
      setUpdating(true);
      enqueue(questionKey, () =>
        createOrUpdateAnswer(params, config)
          .then((payload) => {
            dispatch(optimisticAnswerSuccess(payload));
          })
          .catch(({ message }) => {
            toast.error(message);
            prevAnswerRef.current
              ? dispatch(setUserAnswer(prevAnswerRef.current))
              : dispatch(removeUserAnswer(params));
          })
          .finally(() => {
            setUpdating(false);
            prevAnswerRef.current = null;
          }),
      );
    };

    // Debounce the API request
    debounce(questionKey, enqueueRequest, debounceTime);
  };

  const removeAnswer = ({ debounceTime = 200 }: IOptions = {}) => {
    // Function to enqueue the request callback
    const enqueueRequest = () => {
      setUpdating(true);
      enqueue(
        questionKey,
        () =>
          new Promise((resolve: any) => {
            setUpdating(false);
            dispatch(removeUserAnswer(questionParams));
            resolve();
          }),
      );
    };

    // Debounce the API request
    debounce(questionKey, enqueueRequest, debounceTime);
  };

  return {
    updating,
    question,
    questionKey,
    surveyUserAnswer,
    submitAnswer,
    removeAnswer,
  };
};

const formatRequestProps = (
  p: Partial<ISurveyUserAnswer>,
  o: Partial<IOptions>,
) => {
  let params: Record<string, any> | FormData = p;
  let config: Record<string, any> = o;

  if (o?.useFormData) {
    params = new FormData();

    Object.entries(p).forEach(([key, value]) => {
      params.append(key, value as string | Blob);
    });

    config = {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      },
    };
  }

  return {
    params,
    config,
  };
};

export default useQuestionForm;
