import './Checklist.css';

interface Props {
  selectedOptions: IOption[];
  options: IOption[];
  setOptions: (options: IOption[]) => void;
  className?: string;
  disabled?: boolean;
}

const Checklist: React.FC<Props> = ({
  selectedOptions,
  setOptions,
  options = [],
  className = '',
  disabled = false,
}) => {
  const getClassName = (): string => {
    const list = ['checklist', className];

    if (disabled) {
      list.push('disabled');
    }

    return list.join(' ');
  };

  return (
    <div className={getClassName()}>
      {options.map((option: IOption) => {
        const checked = selectedOptions.some(
          ({ value }) => value === option.value,
        );

        const toggleOption = () => {
          const options = checked
            ? selectedOptions.filter(({ value }) => value !== option.value)
            : [...selectedOptions, option];

          setOptions(options);
        };

        return (
          <div
            key={option.value}
            className={`card ${checked ? 'selected' : ''}`}
            onClick={toggleOption}
          >
            <div className='card-body'>
              <div className='form-check d-flex align-items-center'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  checked={checked}
                  readOnly={true}
                  disabled={disabled}
                />
                <label
                  className='form-check-label ms-3'
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Checklist;

export interface IOption {
  label: string;
  value: any;
}
