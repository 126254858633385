import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IQuiz from '../../../../interfaces/IQuiz';
import OpenGraph from '../../../../components/OpenGraph';
import QuizLayout from '../../../../components/layout/QuizLayout';
import SetUpProfileForm from '../../../../components/quiz/forms/SetUpProfileForm';
import useOpenGraph from '../useOpenGraph';

interface Props {
  quiz: IQuiz;
}

const StartQuizPage: React.FC<Props> = ({ quiz }) => {
  const navigate = useNavigate();
  const ogProps = useOpenGraph();

  const header = {
    title: quiz.name,
  };

  const progress = {
    step: 1,
    totalSteps: quiz?.quiz_surveys?.length + 1,
    title: 'Set-up your profile',
    percentage: 0,
  };

  const navbar = {
    leftAction: {
      content: 'Cancel',
      onClick: () => navigate('/'),
    },
  };

  return (
    <>
      <OpenGraph {...ogProps} />
      <QuizLayout header={header} proggres={progress} navbar={navbar}>
        <SetUpProfileForm quiz={quiz} />
      </QuizLayout>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    quiz: state.quiz,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StartQuizPage);
