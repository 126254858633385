import React from 'react';
import { useSeededShuffle } from '../../../../libs/hooks/useSeededShuffle';
import { IOption } from '../../../range-slider/RangeSlider';
import { InputProps } from './types';
import IAnswer from '../../../../interfaces/IAnswer';
import Checklist from '../../../checklist/Checklist';

const ChecklistInput: React.FC<InputProps> = ({
  inputId,
  question,
  surveyUserAnswer,
  onChange,
  onDelete,
  disabled,
}) => {
  const answers: IAnswer[] = question.answers || [];
  const options = answers.map(({ id, name }: IAnswer) => ({
    label: name,
    value: id,
  }));

  const selectedAnswerIds: any = surveyUserAnswer?.value || [];
  const selectedOptions = options.filter((x) =>
    selectedAnswerIds.includes(x.value),
  );

  const randomizedOptions = useSeededShuffle(options, inputId);

  const setOptions = (options: IOption[]) => {
    if (disabled) return;

    if (options.length > 0) {
      onChange({ value: options.map(({ value }) => value) });
    } else {
      onDelete();
    }
  };

  return (
    <Checklist
      selectedOptions={selectedOptions}
      options={randomizedOptions}
      setOptions={setOptions}
      disabled={disabled}
      className='fs-14'
    />
  );
};

export default ChecklistInput;
