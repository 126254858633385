import { useMemo } from 'react';

/**
 * Custom hook for generating a seeded shuffle of an array
 * @param array The original array to be shuffled
 * @param seed The seed string for consistent randomization
 * @returns Shuffled array
 */
export function useSeededShuffle<T>(array: T[], seed: string): T[] {
  return useMemo(() => {
    // Bail out if no seed or empty array
    if (!seed || array.length === 0) return array;

    // Simple hash function to convert seed string to a number
    const hashSeed = (str: string): number => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32-bit integer
      }
      return Math.abs(hash);
    };

    // Seedable random number generator
    const seededRandom = (seed: number): number => {
      const x = Math.sin(seed) * 10000;
      return x - Math.floor(x);
    };

    // Create a copy of the original array
    const shuffledArray = [...array];

    // Fisher-Yates shuffle algorithm with seeded randomness
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const seedValue = hashSeed(`${seed}-${i}`);
      const j = Math.floor(seededRandom(seedValue) * (i + 1));

      // Swap elements
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }

    return shuffledArray;
  }, [array, seed]);
}
