import { ReactElement } from 'react';
import './TilePicker.css';

interface Option {
  label: string;
  value: any;
  icon?: ReactElement;
}

interface Props {
  selectedOption?: Option;
  setOption?: (option: Option) => void;
  options?: Option[];
  className?: string;
}

const TilePicker: React.FC<Props> = ({
  selectedOption,
  setOption = () => {},
  options = [],
  className = '',
}) => {
  return (
    <div className={`tile-picker ${className}`}>
      {options.map((option: Option) => (
        <div
          key={option.value}
          className={`card ${
            selectedOption?.value === option.value ? 'selected' : ''
          }`}
          onClick={() => setOption(option)}
        >
          <div className='card-body'>
            {option.icon}
            <span className='mt-2'>{option.label}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TilePicker;
