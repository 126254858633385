import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import IQuiz from '../../../../interfaces/IQuiz';
import OpenGraph from '../../../../components/OpenGraph';
import QuizLayout from '../../../../components/layout/QuizLayout';
import CompareProfileForm from '../../../../components/quiz/forms/CompareProfileForm';
import useOpenGraph from '../useOpenGraph';
import Disclaimer from '../../../../components/quiz/compare/Disclaimer';

interface Props {
  quiz: IQuiz;
}

const StartQuizPage: React.FC<Props> = ({ quiz }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const navigate = useNavigate();
  const ogProps = useOpenGraph();

  const header = {
    title: quiz.name,
  };

  const progress = {
    step: 1,
    totalSteps: quiz?.quiz_surveys?.length + 1,
    title: 'Set-up your profile',
    percentage: 0,
  };

  const navbar = {
    leftAction: {
      content: 'Cancel',
      onClick: () => navigate('/'),
    },
  };

  return (
    <>
      <OpenGraph {...ogProps} />
      <QuizLayout header={header} proggres={progress} navbar={navbar}>
        <CompareProfileForm quiz={quiz} />
        <Disclaimer isOpened={showDisclaimer} setIsOpened={setShowDisclaimer} />
      </QuizLayout>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    quiz: state.quiz,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StartQuizPage);
