import React from 'react';
import { connect } from 'react-redux';
import { getImageSrc } from '../../../libs/helpers/imageHelper';
import { getSurveyQuestions } from '../../../libs/redux/actions/surveyQuestionsActions';
import IQuizSurvey from '../../../interfaces/IQuizSurvey';
import ISurvey from '../../../interfaces/ISurvey';
import ISurveyUserAnswer from '../../../interfaces/ISurveyUserAnswer';
import QuestionContainer from '../../question-container/QuestionContainer';
import ContinueLaterModal from '../ContinueLaterModal';
import SubmitButton from '../../SubmitButton';
import QuestionForm from '../forms/question/QuestionForm';
import useQuizSurvey from './useQuizSurvey';
import './QuizSurvey.css';

const QuizSurvey: React.FC<any> = (props) => {
  const {
    isLoading,
    isSubmitting,
    isSubmitted,
    surveyStat,
    userAnswers,
    questionPagination,
    submit,
    skip,
    questionIdx,
    setQuestionIdx,
    showIntroComponent,
    IntroComponent,
    getIntroProps,
    settings,
  } = useQuizSurvey(props);

  const quizSurvey: IQuizSurvey = surveyStat.quiz_survey;
  const survey: ISurvey = quizSurvey.survey;
  const scrollConfig: object = settings.getScrollConfig();
  const { name, description, image } = survey;
  const { current_page, per_page, total, data } = questionPagination;

  return (
    <div className={`quiz-survey ${isLoading ? 'loading' : ''}`}>
      {showIntroComponent() ? (
        <IntroComponent {...getIntroProps()} />
      ) : (
        <React.Fragment>
          <div className='header'>
            <div className='d-flex flex-wrap align-items-center mb-4'>
              {survey.image && (
                <img src={getImageSrc(image)} alt='Survey' className='image' />
              )}
              <h3 className='title'>{name}</h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <hr className='my-5' />
          <QuestionContainer
            focusIndex={questionIdx}
            setFocusIndex={setQuestionIdx}
            totalCount={total}
            countFrom={(current_page - 1) * per_page + 1}
            scrollConfig={scrollConfig}
            questions={data.map((question) => {
              const { id, name: title, description } = question;
              const surveyUserAnswer: ISurveyUserAnswer | undefined =
                userAnswers.find(
                  (x: ISurveyUserAnswer) => x.question_id === id,
                );
              const isAnswered = surveyUserAnswer !== undefined;

              return {
                title,
                description,
                isAnswered,
                children: (
                  <QuestionForm
                    surveyStat={surveyStat}
                    question={question}
                    surveyUserAnswer={surveyUserAnswer}
                    showValidationError={isSubmitted}
                  />
                ),
              };
            })}
          />
          <div className='action-wrapper'>
            <div className='d-flex flex-wrap-reverse flex-sm-nowrap align-items-center justify-content-center'>
              {settings.canContinueLater(current_page) && (
                <ContinueLaterModal className='btn btn-light w-100 me-0 me-sm-3 mt-3 mt-sm-0'>
                  Continue later
                </ContinueLaterModal>
              )}
              {settings.isSkipOnEndVisible() && (
                <SubmitButton
                  onClick={skip}
                  isSubmitting={isLoading}
                  bsColor='light'
                  className='me-0 me-sm-3 mt-3 mt-sm-0'
                >
                  Skip this session
                </SubmitButton>
              )}
              <SubmitButton onClick={submit} isSubmitting={isSubmitting}>
                Continue
              </SubmitButton>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = ({ surveyQuestions }: any) => {
  return {
    questionPagination: surveyQuestions.questions,
    userAnswers: surveyQuestions.userAnswers,
  };
};

const mapDispatchToProps = {
  getSurveyQuestions,
};
export default connect(mapStateToProps, mapDispatchToProps)(QuizSurvey);
