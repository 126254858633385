import { connect } from 'react-redux';
import {
  getQuizStat,
  invalidateQuizStatState,
} from '../../libs/redux/actions/quizStatActions';
import { invalidateSurveyQuestionsState } from '../../libs/redux/actions/surveyQuestionsActions';
import {
  completeSurveyStat,
  skipSurveyStat,
  startSurveyStat,
} from '../../libs/redux/actions/surveyStatActions';
import OpenGraph from '../../components/OpenGraph';
import QuizProvider from '../../libs/context/QuizProvider';
import QuizLayout from '../../components/layout/QuizLayout';
import Loader from '../../components/quiz/loader/Loader';
import QuizSurvey from '../../components/quiz/survey/QuizSurvey';
import useQuizPage from './useQuizPage';
import useOpenGraph from './useOpenGraph';

const QuizPage = (props: any) => {
  const {
    isLoading,
    setIsLoading,
    quizStat,
    surveyStat,
    surveyStatCount,
    currentIndex,
    startStep,
    stepBack,
    skipStep,
    completeStep,
    navbar,
    setNavbar,
    stepProgress,
    setStepProgress,
  }: any = useQuizPage(props);

  const ogProps = useOpenGraph(surveyStat);

  const header = {
    title: quizStat?.name,
  };

  const percentage = calculatePercentage(
    surveyStatCount,
    currentIndex,
    stepProgress,
  );

  const progress = {
    step: currentIndex + 2,
    totalSteps: surveyStatCount + 1,
    title: surveyStat?.quiz_survey?.survey?.name,
    percentage,
  };

  return (
    <QuizProvider>
      <OpenGraph {...ogProps} />
      <QuizLayout
        header={header}
        proggres={progress}
        navbar={navbar}
        showFooter={!isLoading}
      >
        {isLoading && <Loader />}
        {surveyStat && (
          <QuizSurvey
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            surveyStat={surveyStat}
            canStepBack={currentIndex > 0}
            completeStep={completeStep}
            startStep={startStep}
            skipStep={skipStep}
            stepBack={stepBack}
            setNavbar={setNavbar}
            setProgress={setStepProgress}
          />
        )}
      </QuizLayout>
    </QuizProvider>
  );
};

const calculatePercentage = (
  surveyCount: number,
  currentIdx: number,
  stepProgress: number,
) => {
  const totalSteps = surveyCount + 1;
  const stepPerc = 100 / totalSteps;

  if (surveyCount === 0) return 0;

  const percentage =
    (currentIdx + 1) * stepPerc + (stepProgress / 100) * stepPerc;

  return Math.round(percentage);
};

const mapStateToProps = (state: any) => {
  return {
    quizStat: state.quizStat,
  };
};

const mapDispatchToProps = {
  getQuizStat,
  startSurveyStat,
  completeSurveyStat,
  skipSurveyStat,
  invalidateQuizStatState,
  invalidateSurveyQuestionsState,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
