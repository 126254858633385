import CoupleModePicker from '../../../../couple/CoupleModePicker';
import './UpgradeStep.css';

const Situation = ({ option, setOption, onSkip, onConfirm, disabled }) => {
  return (
    <div className='upgrade-step'>
      <div className='body'>
        <div className='title'>What is your current situation?</div>
        <CoupleModePicker
          selectedOption={option}
          setOption={setOption}
          className='flex-wrap flex-sm-nowrap'
        />
      </div>
      <div className='foot flex-wrap-reverse flex-sm-nowrap'>
        <button
          className='btn btn-light w-100'
          onClick={onSkip}
          disabled={disabled}
        >
          Skip to my report
        </button>
        <button
          className='btn btn-secondary w-100'
          onClick={onConfirm}
          disabled={disabled}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Situation;
