import React from 'react';
import IAnswer from '../../../../interfaces/IAnswer';
import RangeSlider, { IOption } from '../../../range-slider/RangeSlider';
import { InputProps } from './types';

const RangeInput: React.FC<InputProps> = ({
  question,
  surveyUserAnswer,
  onChange,
  disabled,
}) => {
  const answers: IAnswer[] = question.answers || [];
  const options: IOption[] = answers.map(({ id, name }: IAnswer) => ({
    label: name,
    value: id,
  }));

  const selectedOption = options.find(
    (x) => x?.value === surveyUserAnswer?.answer_id,
  );

  const setOption = ({ value }: IOption) =>
    !disabled && onChange({ answer_id: value });

  return (
    <RangeSlider
      options={options}
      selectedOption={selectedOption}
      setOption={setOption}
      disabled={disabled}
    />
  );
};

export default RangeInput;
