import React from 'react';
import { useSeededShuffle } from '../../../../libs/hooks/useSeededShuffle';
import { InputProps } from './types';
import IAnswer from '../../../../interfaces/IAnswer';
import RadioList from '../../../radio-list/RadioList';

const RadioInput: React.FC<InputProps> = ({
  inputId,
  question,
  surveyUserAnswer,
  onChange,
  disabled,
}) => {
  const answers: IAnswer[] = question.answers || [];
  const options = answers.map(({ id, name }: IAnswer) => ({
    label: name,
    value: id,
  }));

  const randomizedOptions = useSeededShuffle(options, inputId);

  const selectedOption = options.find(
    (x) => surveyUserAnswer?.answer_id === x.value,
  );

  const changeHandler = (option: { value: number } | null) => {
    if (disabled || !option) return;

    onChange({ answer_id: option.value });
  };

  return (
    <RadioList
      name={`radio-${inputId}`}
      selectedOption={selectedOption}
      options={randomizedOptions}
      onChange={changeHandler}
      disabled={disabled}
      className='fs-14'
    />
  );
};

export default RadioInput;
