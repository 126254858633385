import IQuestion from '../../interfaces/IQuestion';
import ISurveyUserAnswer from '../../interfaces/ISurveyUserAnswer';
import useIndexTracker from './useIndexTracker';
import * as questionTypes from '../helpers/questionTypeHelper';

interface Props {
  questions: IQuestion[];
  answers: ISurveyUserAnswer[];
}

interface IQuestionTracker {
  questionIdx: number;
  setQuestionIdx: (index: number) => void;
}

const useQuestionTracker = ({
  questions,
  answers,
}: Props): IQuestionTracker => {
  const formatTrackerItem = (question: IQuestion) => {
    const answer = answers.find((x) => x.question_id === question.id);
    const trackValue = !typesSkipIndexing.includes(question.type?.key);
    const value = answer?.answer_id || answer?.value || answer?.text;

    return {
      value: trackValue ? value : undefined,
      required: trackValue,
    };
  };

  const { index: questionIdx, setIndex: setQuestionIdx } = useIndexTracker({
    items: questions.map(formatTrackerItem),
    defaultLast: true,
  });

  return { questionIdx, setQuestionIdx };
};

const typesSkipIndexing = [
  questionTypes.CHECKLIST,
  questionTypes.TEXTAREA,
  questionTypes.AUDIO_OR_TEXT,
];

export default useQuestionTracker;
