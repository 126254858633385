import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { genderList } from '../../../libs/helpers/genderHelper';
import { setQuizAccessToken } from '../../../libs/services/quizAccessService';
import IQuiz from '../../../interfaces/IQuiz';
import useIndexTracker from '../../../libs/hooks/useIndexTracker';

interface Props {
  slug: string;
  quiz: IQuiz;
  createQuizStat: (params: any) => Promise<any>;
}

const useSetUpProfileForm = ({ quiz, createQuizStat }: Props) => {
  const [situation, setSituation]: any = useState(null);
  const [age, setAge]: any = useState(null);
  const [location, setLocation]: any = useState(null);
  const [gender, setGender]: any = useState(null);
  const [kids, setKids]: any = useState(null);
  const [isSubmitting, setIsSubmiting]: [boolean, any] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { index: questionIndex, setIndex: setQuestionIndex } = useIndexTracker({
    items: [
      { value: situation?.value },
      { value: age?.value },
      {
        value:
          (location?.country &&
            (!location.isStateAvailable || location.state)) ??
          null,
      },
      { value: gender?.value },
      { value: kids?.value },
    ],
  });

  useEffect(() => {
    if (location?.isGeolocated) {
      setQuestionIndex(-1);
    }
  }, [location]);

  const getFormParams = () => {
    const params: any = {
      quiz_id: quiz.id,
      situation: situation?.value,
      age: age?.value,
      gender: gender?.value,
      kids: kids?.value,
      country: location?.country,
      state_of_usa: location?.state,
    };

    Object.entries(params).forEach(
      ([key, value]) => value === undefined && delete params[key],
    );

    return params;
  };

  const submitForm = () => {
    setIsSubmiting(true);

    const params = getFormParams();

    createQuizStat(params)
      .then(({ token }: any) => {
        setQuizAccessToken(quiz.slug, token);
        redirectToNextStep(token);
      })
      .catch(({ message }) => {
        setIsSubmiting(false);
        toast.error(message);
      });
  };

  const redirectToNextStep = (token: string) => {
    const quizSlug = quiz.slug;
    const surveySlug = quiz.quiz_surveys[0]?.slug;
    const basePath = `/${quizSlug}/${surveySlug}?token=${token}`;
    const queryParamsStr = searchParams.toString();

    const to =
      queryParamsStr.length > 0 ? `${basePath}&${queryParamsStr}` : basePath;

    navigate(to);
  };

  return {
    isSubmitting,
    situation,
    setSituation,
    age,
    setAge,
    gender,
    setGender,
    kids,
    setKids,
    location,
    setLocation,
    genderOptions,
    kidsOptions,
    submitForm,
    questionIndex,
    setQuestionIndex,
  };
};

const genderOptions = Object.entries(genderList).map(([value, label]) => ({
  label,
  value,
}));

const kidsOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

export default useSetUpProfileForm;
