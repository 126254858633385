import { useState } from "react";
import { Link, useParams } from 'react-router-dom';
import env from '../../env';
import styles from './footer.module.css';
import CollapsibleContent from '../collapsible-content/CollapsibleContent';
import { getReferenceBySlug } from '../../libs/helpers/ReferencesHelper';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { step = '' } = useParams();

  return (
    <footer className={styles.footer}>
      <div className='container d-flex flex-column justify-content-between h-100'>
        <div className='d-flex flex-wrap'>
          <div className='me-auto pe-3 mb-2 mb-sm-0'>
            © 2020-{currentYear} MindOnly Pty Ltd
          </div>
          { getReferenceBySlug(step) && (
          <p 
          className={styles.link}
          onClick={() => setIsCollapsed(!isCollapsed)}
          >
            References
            </p>
          )}
          <Link
            to={`${env.wordpressUrl}/terms`}
            className={`${styles.link} me-3 mx-md-5`}
            target='_blank'
          >
            Terms
          </Link>
          <Link
            to={`${env.wordpressUrl}/privacy-policy`}
            className={styles.link}
            target='_blank'
          >
            Privacy
          </Link>
        </div>
        { getReferenceBySlug(step) && (
        <CollapsibleContent isCollapsed={isCollapsed} style={{opacity:.5}}>
        <p>{ getReferenceBySlug(step) }</p>
      </CollapsibleContent>
        )}
        <div className={styles.disclaimer}>
          The Attachment Project’s content courses are for informational and
          educational purposes only. Our website and products are not intended
          to be a substitute for professional medical and/or psychological
          advice, diagnosis, or treatment.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
