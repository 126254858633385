import { handleError, handleResponse } from './utils';
import axiosAuthInstance from './axios/authInstance';

const baseUrl = '/answer';

export function createOrUpdateAnswer(params, config) {
  return axiosAuthInstance
    .post(baseUrl, params, config)
    .then(handleResponse)
    .catch(handleError);
}
