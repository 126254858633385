import React, { useEffect, useState } from 'react';
import { ReactComponent as DotIcon } from '../../assets/icons/dot.svg';
import './RangeSlider.css';

export interface IOption {
  label: string | TrustedHTML;
  value: any;
}

interface Props {
  options: IOption[];
  selectedOption: any;
  isActive?: boolean;
  placeholder?: string;
  step?: number;
  disabled?: boolean;
  setOption: (option: IOption) => void;
  [key: string]: any;
}

const RangeSlider: React.FC<Props> = ({
  options,
  selectedOption,
  setOption,
  placeholder = 'Slide the button',
  step = 0.1,
  disabled = false,
  ...props
}) => {
  const [optionIdx, setOptionIdx] = useState<number>(-1);

  const optionCnt = options.length;
  const active = selectedOption !== undefined;
  const value =
    optionIdx > -1 ? optionIdx : optionCnt % 2 === 0 ? 0 : (optionCnt - 1) / 2;

  useEffect(() => {
    setOptionIdx(() =>
      options.findIndex((o) => o?.value === selectedOption?.value),
    );
  }, [selectedOption]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value);
    const optionIdx = Math.round(inputValue);
    setOptionIdx(optionIdx);
  };

  const onPointerUpHandler = (e: React.MouseEvent | React.TouchEvent) => {
    const option = options[optionIdx];

    if (option) {
      setOption(option);
    } else {
      const input = e.target as HTMLInputElement;
      // Get the click position relative to the range input
      const clickX =
        'changedTouches' in e
          ? e.changedTouches[0].clientX
          : (e as React.MouseEvent).clientX;

      setClosestOption(input, clickX);
    }
  };

  const setClosestOption = (input: HTMLInputElement, x: number) => {
    const inputRect = input.getBoundingClientRect();

    const clickOffset = x - inputRect.left - 20;
    const inputWidth = inputRect.width;
    const closestOptionIdx = Math.round(
      (clickOffset / inputWidth) * (optionCnt - 1),
    );

    const option = options[closestOptionIdx];

    if (option) {
      setOption(option);
    }
  };

  const getTickWrapperStyle = (): React.CSSProperties => {
    const optionWidthPerc = 100 / optionCnt;
    const widthPerc = 100 + optionWidthPerc;

    return {
      width: `${widthPerc}%`,
      margin: `0 -${optionWidthPerc / 2}%`,
    };
  };

  const getTickStyle = (): React.CSSProperties => ({
    minWidth: `calc(100% / ${optionCnt})`,
  });

  const getClassName = (): string => {
    const list = ['range-slider-wrapper'];

    if (active) {
      list.push('active');
    }

    if (disabled) {
      list.push('disabled');
    }

    return list.join(' ');
  };

  return (
    <div className={getClassName()}>
      <label
        dangerouslySetInnerHTML={{
          __html: selectedOption?.label || placeholder,
        }}
      />
      <div className='range-slider'>
        <input
          type='range'
          className='custom-range-slider'
          min={0}
          max={optionCnt - 1}
          step={step}
          onChange={onChangeHandler}
          onMouseUp={onPointerUpHandler}
          onTouchEnd={onPointerUpHandler}
          value={value}
          disabled={disabled}
          {...props}
        />
        <div className='dot-wrapper'>
          {options.map((_x, index) => (
            <DotIcon key={`dot-${index}`} />
          ))}
        </div>
        <div className='tick-wrapper' style={getTickWrapperStyle()}>
          {options.map(({ label }, index) => (
            <div
              className='tick'
              key={`${label}-${index}`}
              dangerouslySetInnerHTML={{ __html: label }}
              style={getTickStyle()}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
