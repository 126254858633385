import { useEffect, useRef, useState } from 'react';

interface Item {
  value: any;
  required?: boolean;
}

interface Props {
  items: Item[];
  defaultLast?: boolean;
}

const useIndexTracker = ({ items, defaultLast = false }: Props) => {
  const [index, setIndex] = useState(0);
  const [prevItems, setPrevItems]: any = useState(items);

  const changeString = items
    .map((item) => `${item.value}${item.required || ''}`)
    .join('');

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) return;
    const nextIdx = getNextItemIdx();

    setIndex(nextIdx);
    setPrevItems(items);
  }, [changeString]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const getNextItemIdx = () => {
    const lastUpdatedIdx = getLastUpdatedItemIdx();

    // If not found, check for the previous required unanswered question
    for (let i = 0; i <= lastUpdatedIdx; i++) {
      if (items[i]?.required && !isThruthyValue(items[i]?.value)) {
        return i;
      }
    }

    // First, check for the next unanswered question
    for (let i = lastUpdatedIdx; i < items.length; i++) {
      if (!isThruthyValue(items[i]?.value)) {
        return i;
      }
    }

    return defaultLast ? items.length - 1 : lastUpdatedIdx;
  };

  const getLastUpdatedItemIdx = () =>
    items.findIndex((q, i) => q.value !== prevItems?.[i]?.value);

  const isThruthyValue = (v: any) => v !== null && v !== undefined && v !== '';

  return { index, setIndex };
};

export default useIndexTracker;
