import * as types from '../actions/types';
import initialState from './initialState';

export default function surveyQuestionsReducer(
  state = initialState.surveyQuestions,
  action,
) {
  switch (action.type) {
    case types.LOAD_SURVEY_QUESTIONS_SUCCESS:
      return action.payload;

    case types.SET_USER_ANSWER:
      return updateUserAnswers(state, action.payload);

    case types.OPTIMISTIC_USER_ANSWER_BEGIN:
      return updateUserAnswers(state, { ...action.payload, optimistic: true });

    case types.OPTIMISTIC_USER_ANSWER_SUCCESS:
      return updateUserAnswers(state, action.payload, (userAnswer, payload) => {
        const updatedAnswer = { ...userAnswer, id: payload.id };
        delete updatedAnswer.optimistic;
        return updatedAnswer;
      });

    case types.REMOVE_USER_ANSWER:
      return {
        ...state,
        userAnswers: state.userAnswers.filter(
          (ua) =>
            ua?.id !== action.payload?.id &&
            !(
              ua.question_id === action.payload?.question_id &&
              ua.survey_stat_id === action.payload?.survey_stat_id
            ),
        ),
      };

    case types.INVALIDATE_SURVEY_QUESTIONS_STATE:
      return initialState.surveyQuestions;

    default:
      return state;
  }
}

const updateUserAnswers = (state, payload, updateFn = (_, p) => p) => {
  const existingIdx = findUserAnswerIndex(state.userAnswers, payload);

  if (existingIdx === -1) {
    // Add new answer
    return {
      ...state,
      userAnswers: [...state.userAnswers, payload],
    };
  }

  // Update existing answer
  return {
    ...state,
    userAnswers: state.userAnswers.map((answer, index) =>
      index === existingIdx ? updateFn(answer, payload) : answer,
    ),
  };
};

const findUserAnswerIndex = (userAnswers, payload) =>
  userAnswers.findIndex(
    (ua) =>
      ua.survey_stat_id === payload.survey_stat_id &&
      ua.question_id === payload.question_id,
  );
