import { useParams } from 'react-router-dom';
import ISurveyStat from '../../interfaces/ISurveyStat';
import * as quizHelper from '../../libs/helpers/quizHelper';
import * as surveyHelper from '../../libs/helpers/surveyHelper';

const DEFAULT = 'default';

interface ConfigType {
  [key: string]: {
    [key: string]: {
      title: string;
    };
  };
}

const useOpenGraph = (surveyStat: ISurveyStat) => {
  const { slug = '' } = useParams();

  const defaultQuizConfig = {
    [DEFAULT]: {
      title: 'The Attachment Project | Survey',
    },
  };

  const config: ConfigType = {
    [quizHelper.RELATIONSHIP_STRUCTURES]: {
      [surveyHelper.MOTHER_CONDITIONS]: {
        title: 'The Attachment Project | Mother Condition',
      },
      [surveyHelper.FATHER_CONDITIONS]: {
        title: 'The Attachment Project | Father Conditions',
      },
      [surveyHelper.RS_MOTHER]: {
        title: 'The Attachment Project | Mother Relationships',
      },
      [surveyHelper.RS_FATHER]: {
        title: 'The Attachment Project | Father Relationships',
      },
      [surveyHelper.RS_PARTNER]: {
        title: 'The Attachment Project | Partner Relationships',
      },
      [surveyHelper.GENERAL_ATTACHMENT_STYLE]: {
        title: 'The Attachment Project | General Attachment',
      },
      [DEFAULT]: {
        title: 'The Attachment Project | Survey',
      },
    },
    [quizHelper.EMOTIONAL_REGULATION]: {
      [DEFAULT]: {
        title: 'The Attachment Project | Emotional Regulation Survey',
      },
    },
    [quizHelper.MALADAPTIVE_DAYDREAMING]: {
      [DEFAULT]: {
        title: 'The Attachment Project | Maladaptive Daydreaming Survey',
      },
    },
    [quizHelper.MALADAPTIVE_SCHEMA]: {
      [DEFAULT]: {
        title: 'The Attachment Project | Maladaptive Schema Survey',
      },
    },
    [quizHelper.SELF_ESTEEM]: {
      [DEFAULT]: {
        title: 'The Attachment Project | Self Esteem Survey',
      },
    },
    [quizHelper.SUPERPOWERS]: {
      [DEFAULT]: {
        title: 'The Attachment Project | Superpowers Survey',
      },
    },
    [quizHelper.LIMERENCE]: {
      [DEFAULT]: {
        title: 'The Attachment Project | Limerence Survey',
      },
    },
    [quizHelper.ROMANTIC_PARTNERS]: {
      [surveyHelper.ROMANTIC_PARTNERS_ER]: {
        title: 'The Attachment Project | Emotional Regulation',
      },
      [DEFAULT]: {
        title: 'The Attachment Project | Partner Relationships',
      },
    },
    [quizHelper.COMPARISON_QUIZ]: {
      [DEFAULT]: {
        title: 'The Attachment Project | Comparison Quiz',
      },
    },
  };

  const quizConfig = config[slug] ?? defaultQuizConfig;

  return quizConfig[surveyStat?.quiz_survey?.slug] || quizConfig[DEFAULT];
};

export default useOpenGraph;
