import * as types from './types';
import * as answerApi from '../../api/userAnswerApi';

export function setUserAnswer(payload) {
  return { type: types.SET_USER_ANSWER, payload };
}

export function removeUserAnswer(payload) {
  return { type: types.REMOVE_USER_ANSWER, payload };
}

export function optimisticAnswerBegin(payload) {
  return {
    type: types.OPTIMISTIC_USER_ANSWER_BEGIN,
    payload,
  };
}

export function optimisticAnswerSuccess(payload) {
  return {
    type: types.OPTIMISTIC_USER_ANSWER_SUCCESS,
    payload,
  };
}

export function createOrUpdateAnswer(params, config = {}) {
  return function () {
    return answerApi.createOrUpdateAnswer(params, config);
  };
}
