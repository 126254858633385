import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuizContext } from '../../../libs/context/QuizProvider';
import { getComponentByKey } from '../../../libs/helpers/surveyIntroductionHelper';
import IQuestion from '../../../interfaces/IQuestion';
import IQuestionPagination from '../../../interfaces/IQuestionPagination';
import ISurveyStat from '../../../interfaces/ISurveyStat';
import ISurveyUserAnswer from '../../../interfaces/ISurveyUserAnswer';
import useQuestionTracker from '../../../libs/hooks/useQuestionTracker';
import useQuizSurveySettings from './useQuizSurveySettings';

interface Props {
  isLoading: boolean;
  canStepBack: boolean;
  surveyStat: ISurveyStat;
  questionPagination: IQuestionPagination;
  userAnswers: ISurveyUserAnswer[];
  setIsLoading: (value: boolean) => Function;
  getSurveyQuestions: (id: number, params?: object) => Promise<any>;
  completeStep: () => Promise<any>;
  skipStep: () => Promise<any>;
  startStep: () => Promise<any>;
  stepBack: () => Function;
  setNavbar: (value: any) => Function;
  setProgress: (value: number) => Function;
}

const useQuizSurvey = ({
  isLoading,
  canStepBack,
  surveyStat,
  questionPagination,
  userAnswers,
  getSurveyQuestions,
  setIsLoading,
  completeStep,
  startStep,
  skipStep,
  stepBack,
  setNavbar,
  setProgress,
}: Props) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSubmitting, setIsSubmitting, submitAndWait } = useQuizContext();
  const { questionIdx, setQuestionIdx } = useQuestionTracker({
    questions: questionPagination.data,
    answers: userAnswers,
  });
  const settings = useQuizSurveySettings(surveyStat.quiz_survey);
  const navigate = useNavigate();

  const IntroComponent = getComponentByKey(settings.getIntroKey());

  useEffect(() => {
    if (!isLoading) {
      scrollToWindowTop();
      updateNavbar();
      updateProgress();
    }
  }, [isLoading]);

  useEffect(() => {
    const { current_page: page, last_page } = questionPagination;

    if (last_page > 1) {
      updateSearchParams({ page });
    }
  }, [questionPagination.current_page]);

  useEffect(() => {
    if (!showIntroComponent()) {
      fetchSurveyQuestions({ page: searchParams.get('page') });
    }
  }, [surveyStat.quiz_survey]);

  useEffect(() => {
    if (hasIntro() && isStarted()) {
      fetchSurveyQuestions({ page: searchParams.get('page') });
    }
  }, [surveyStat.is_started]);

  useEffect(() => {
    const notStarted = !isStarted();
    const hasIntroPage = hasIntro();

    if (notStarted && !hasIntroPage) {
      start();
    }

    if (notStarted && hasIntroPage) {
      setIsLoading(false);
    }
  }, [surveyStat.id]);

  const fetchSurveyQuestions = (params = {}) => {
    setIsLoading(true);
    getSurveyQuestions(surveyStat.id, params)
      .catch(({ message }) => {
        toast.error(message);
      })
      .finally(() => {
        setQuestionIdx(-1);
        setIsLoading(false);
      });
  };

  const submit = () => {
    setIsSubmitted(true);

    if (validate()) {
      submitAndWait(() => {
        if (isLastPage()) {
          completeStep()
            .then(() => setIsLoading(true))
            .catch(({ message }) => toast.error(message))
            .finally(() => setIsSubmitting(false));
        } else {
          setIsSubmitting(false);
          fetchSurveyQuestions({ page: questionPagination.current_page + 1 });
        }
      });
      setIsSubmitted(false);
    }
  };

  const skip = () => {
    setIsLoading(true);
    return skipStep().catch(({ message }) => {
      setIsLoading(false);
      toast.error(message);
    });
  };

  const start = () => {
    setIsLoading(true);
    return startStep();
  };

  const canGoBack = () => {
    return canStepBack || questionPagination.current_page > 1;
  };

  const goBack = () => {
    const { current_page } = questionPagination;

    if (current_page > 1) {
      fetchSurveyQuestions({ page: current_page - 1 });
    } else {
      setIsLoading(true);
      stepBack();
    }
  };

  const validate = () => {
    for (let idx = 0; idx < questionPagination.data.length; idx++) {
      const question = questionPagination.data[idx];
      if (!validateSurveyUserAnswer(question)) {
        setQuestionIdx(idx);
        return false;
      }
    }
    return true;
  };

  const validateSurveyUserAnswer = (question: IQuestion): boolean =>
    userAnswers.some(
      ({ question_id }: ISurveyUserAnswer) => question.id === question_id,
    );

  const isLastPage = () =>
    questionPagination.last_page === questionPagination.current_page;

  const scrollToWindowTop = () => {
    const options: any = {
      top: 96,
      left: 0,
      behavior: 'instant',
    };

    window.scrollTo(options);
  };

  const updateSearchParams = (params = {}) => {
    const sParams = Array.from(searchParams.entries()).reduce(
      (obj: any, [key, val]: [any, any]) => ({ ...obj, [key]: val }),
      {},
    );

    const newSearchParams: any = {
      ...sParams,
      ...params,
    };

    setSearchParams(newSearchParams);
  };

  const updateNavbar = () => {
    const leftAction = canGoBack()
      ? {
          content: 'Back',
          onClick: goBack,
        }
      : { content: 'Cancel', onClick: () => navigate('/') };

    const rightAction = canSkip()
      ? {
          content: 'Skip',
          onClick: skip,
          props: { disabled: isLoading },
        }
      : null;

    setNavbar({ leftAction, rightAction });
  };

  const updateProgress = () => {
    const { current_page, last_page } = questionPagination;

    if (current_page && last_page) {
      setProgress(((current_page - 1) / last_page) * 100);
    }
  };

  const canSkip = () => settings.isSkippable() || isPartnerSkippable();

  const isPartnerSkippable = (): boolean => {
    return surveyStat?.is_partner_skippable === 1;
  };

  const isStarted = () => surveyStat.is_started === 1;

  const hasIntro = () => IntroComponent !== null;

  const showIntroComponent = () => !isStarted() && hasIntro();

  const getIntroProps = () => ({
    skippable: canSkip(),
    skip,
    start,
  });

  return {
    isLoading,
    isSubmitting,
    isSubmitted,
    surveyStat,
    userAnswers,
    questionPagination,
    submit,
    skip,
    questionIdx,
    setQuestionIdx,
    showIntroComponent,
    IntroComponent,
    getIntroProps,
    settings,
  };
};

export default useQuizSurvey;
