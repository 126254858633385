import React, { useEffect, useRef, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { ReactComponent as MicIcon } from '../../assets/icons/microphone.svg';
import useVisualisation from './useVisualisation';
import styles from './audio.recorder.module.css';

interface Props {
  minVolume?: number;
  maxRecordingTime?: number;
  uploadedTrack?: IUploadedFile;
  uploading?: boolean;
  onRecordingStart: () => void;
  onRecordingComplete: (
    recordingBlob: Blob | undefined,
    duration: number,
  ) => void;
}

interface IUploadedFile {
  duration: number;
}

const AudioRecorder: React.FC<Props> = ({
  minVolume = 4,
  maxRecordingTime = 90,
  onRecordingStart,
  onRecordingComplete,
  uploadedTrack,
  uploading = false,
}) => {
  const [recordedTime, setRecordedTime] = useState<number>(
    uploadedTrack?.duration ?? 0,
  );
  const mounted = useRef<boolean>(false);

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder({ deviceId: 'default' }, ({ message }) =>
    toast.error(message),
  );

  const { volume } = useVisualisation(mediaRecorder);

  const exeededRecordingLimit = recordingTime >= maxRecordingTime;

  const recorderClassName =
    isRecording && !isPaused ? styles.recorderActive : styles.recorderInactive;
  const controlClassName = `${styles.control} ${
    volume >= minVolume ? styles.visualize : ''
  }`;

  const controlClickHandler = useCallback(() => {
    if (uploading) return;

    if (isRecording && recordingTime > 0) {
      setRecordedTime(recordingTime);
      stopRecording();
    } else {
      startRecording();
      onRecordingStart();
    }
  }, [uploading, isRecording, recordingTime, startRecording, stopRecording]);

  // Handle exceeding recording time
  useEffect(() => {
    if (exeededRecordingLimit) {
      setRecordedTime(maxRecordingTime);
      stopRecording();
    }
  }, [recordingTime, exeededRecordingLimit, stopRecording]);

  useEffect(() => {
    if (mounted.current && recordingBlob) {
      onRecordingComplete(recordingBlob, recordedTime);
    }
  }, [recordingBlob]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // Format time as mm:ss
  const formattedTime = isRecording
    ? formatTime(recordingTime)
    : recordedTime > 0
    ? formatTime(recordedTime)
    : '';

  return (
    <div className={recorderClassName}>
      <div className='mb-4'>
        You will have {maxRecordingTime} seconds to speak
      </div>
      <div className={controlClassName} onClick={controlClickHandler}>
        <MicIcon />
      </div>
      <div className={styles.message}>
        {isRecording
          ? 'Recording response...'
          : uploadedTrack
          ? uploading
            ? 'Uploading...'
            : 'Recorded response'
          : 'Press to record your response'}
      </div>
      <div className={styles.timer}>{formattedTime}</div>
    </div>
  );
};

// Helper function to format time as mm:ss
function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  }`;
}

export default AudioRecorder;
