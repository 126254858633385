import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../libs/hooks/useDebounce';
import Question from './Question';
import './QuestionContainer.css';

interface IQuestion {
  title?: string;
  description?: string;
  children: any;
  number?: number;
  showNumber?: boolean;
  showSeparator?: boolean;
  isAnswered?: boolean;
}

interface Props {
  questions: IQuestion[];
  focusIndex: number;
  setFocusIndex: any;
  autoHighlight?: boolean;
  countFrom?: number;
  totalCount?: number;
  scrollConfig?: {
    behavior?: 'smooth|instant|auto';
    block?: 'start|center|end|nearest';
  };
}

const QuestionContainer: React.FC<Props> = ({
  questions,
  totalCount,
  countFrom = 1,
  focusIndex = -1,
  autoHighlight = true,
  setFocusIndex,
  scrollConfig = {},
}) => {
  const [highlightIndex, setHiglightIndex]: [number, any] = useState(0);
  const isMounted: any = useRef(false);
  const questionsRef: any = useRef([]);
  const debounce = useDebounce();

  const questionCount = totalCount || questions.length;

  useEffect(() => {
    if (isMounted.current && focusIndex > -1) {
      scrollToQuestion(focusIndex);
    }

    return () => {
      setFocusIndex(-1);
    };
  }, [focusIndex]);

  useEffect(() => {
    autoHighlight && window.addEventListener('scroll', scrollHandler);

    return () => {
      autoHighlight && window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const scrollHandler = () => {
    debounce(
      'scroll',
      () => {
        let newHighlightIdx = findFirstUnansweredQuestionIdx();

        if (newHighlightIdx < 0) {
          newHighlightIdx = findLastAnsweredQuestionIdx();
        }

        setHiglightIndex(newHighlightIdx);
      },
      10,
    );
  };

  const findFirstUnansweredQuestionIdx = (): number => {
    const viewportHeight = window.innerHeight;
    const questionElements = questionsRef.current;

    return questionElements.findIndex((elem: any) => {
      if (!elem || elem.dataset.isAnswered === 'true') return false;

      const { top, bottom } = elem.getBoundingClientRect();

      return top >= 0 && bottom <= viewportHeight;
    });
  };

  const findLastAnsweredQuestionIdx = () => {
    let closestIndex = -1;
    let highestVisibility = 0;
    let closestDistance = Number.MAX_VALUE;
    const viewportHeight = window.innerHeight;
    const questionElements = questionsRef.current;

    questionElements.forEach((element: HTMLElement, i: number) => {
      if (!element) {
        return;
      }

      const clientRect = element.getBoundingClientRect();

      // If element top is bellow viewport skip it
      if (clientRect.top > viewportHeight) {
        return;
      }

      // Calculate visibility percentage
      const visiblePercentage =
        clientRect.height > 0
          ? Math.min(
              1,
              Math.max(
                0,
                (Math.min(clientRect.bottom, viewportHeight) -
                  Math.max(clientRect.top, 0)) /
                  clientRect.height,
              ),
            )
          : 0;

      const distanceToBottom = viewportHeight - clientRect.top;

      // Prioritize visibility percentage
      // If visibility is higher, update
      // If visibility is the same, compare distance to bottom
      if (
        visiblePercentage > highestVisibility ||
        (visiblePercentage === highestVisibility &&
          distanceToBottom < closestDistance)
      ) {
        closestIndex = i;
        highestVisibility = visiblePercentage;
        closestDistance = distanceToBottom;
      }
    });

    return closestIndex;
  };

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const scrollToQuestion = (index: number) => {
    questionsRef.current[index] &&
      questionsRef.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        ...scrollConfig,
      });
  };

  return (
    <div
      className={`question-container ${autoHighlight ? 'auto-highlight' : ''}`}
    >
      {questions.map(
        (
          {
            title = '',
            description,
            children,
            number,
            showNumber = true,
            showSeparator = true,
            isAnswered = false,
          },
          index,
        ) => {
          const questionNumber = number ?? countFrom + index;
          const classList = ['question-wrapper'];

          if (autoHighlight && highlightIndex === index) {
            classList.push('active');
          }

          const className = classList.join(' ');

          return (
            <div
              key={index}
              className={className}
              ref={(el) => (questionsRef.current[index] = el)}
              onClick={() => (focusIndex = -1)}
              data-is-answered={isAnswered}
            >
              {showNumber && (
                <small className='d-block'>
                  {questionNumber} of {questionCount} questions
                </small>
              )}
              <Question title={title} description={description}>
                {children}
              </Question>
              {showSeparator && <hr className='my-5' />}
            </div>
          );
        },
      )}
    </div>
  );
};

export default QuestionContainer;
