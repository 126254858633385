import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  getQuizBySlug,
  invalidateQuizState,
} from '../../../libs/redux/actions/quizActions';
import * as quizHelper from '../../../libs/helpers/quizHelper';
import IQuiz from '../../../interfaces/IQuiz';
import Loader from '../../../components/quiz/loader/Loader';
import StartRegularQuiz from './regular/StartQuizPage';
import StartCoupleQuiz from './couple/StartQuizPage';
import StartCompareQuiz from './compare/StartQuizPage';

interface Props {
  quiz: IQuiz;
  getQuizBySlug: (slug: string) => Promise<any>;
  invalidateQuizState: () => any;
}

const StartQuizPage: React.FC<Props> = ({ quiz, getQuizBySlug }) => {
  const [searchParams]: [URLSearchParams, any] = useSearchParams();
  const [isLoading, setIsLoading]: [boolean, any] = useState(true);
  const { slug = '' } = useParams();
  const navigate = useNavigate();

  const isInviter = searchParams.get('hash') === null;

  const isRegularMode = () => quiz.mode === quizHelper.MODE_REGULAR;

  const isCoupleMode = () => quiz.mode === quizHelper.MODE_COUPLE;

  const isCompareMode = () => quiz.mode === quizHelper.MODE_COMPARE;

  useEffect(() => {
    fetchQuiz();

    return () => {
      invalidateQuizState();
    };
  }, []);

  const fetchQuiz = () => {
    getQuizBySlug(slug)
      .then(() => setIsLoading(false))
      .catch(({ code, message }) => {
        if (code === 503) {
          toast.error(message);
        }

        navigate('/');
      });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {isRegularMode() && <StartRegularQuiz />}
      {isCoupleMode() && <StartCoupleQuiz isInviter={isInviter} />}
      {isCompareMode() && <StartCompareQuiz />}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    quiz: state.quiz,
  };
};

const mapDispatchToProps = {
  getQuizBySlug,
  invalidateQuizState,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartQuizPage);
